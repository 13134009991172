<template>
    <layout>
        <div>
            <!-- Info -->
            <div class="mb-2">
                <info-collapse-area>
                    <template v-slot:title>
                        <div class="w-100 d-flex flex-column flex-md-row justify-content-md-between">
                            <div class="mt-1 mt-md-0 mb-1 mb-md-0 fs-16">
                                {{ getObjectValue(application, 'name') }} {{ getObjectValue(application, 'surname') }}
                            </div>
                            <div class="mb-1 mb-md-0 mr-0 mr-md-4">
                                <template v-if="application.result">
                                    <b-badge
                                        :variant="(application.result == 'placed' ? 'success' : 'danger') + ' text-uppercase'">
                                        {{ $t(`application_result.${application.result}`) }}
                                    </b-badge>
                                </template>
                                <template v-else>
                                    {{ applicationStatusMessage }}
                                </template>
                            </div>
                        </div>
                    </template>
                    <template v-slot:detail>
                        {{ getObjectValue(application, 'name') }}
                        {{ getObjectValue(application, 'surname') }}
                    </template>
                </info-collapse-area>
            </div>
            <div class="border rounded-sm pt-4 pl-4 pr-4">
                <b-tabs content-class="py-5 position-relative" class="line-tabs" fill>
                    <div class="tabs-bottom-line"></div>
                    <b-tab :active="tabIndex == 0" @click="tabIndex = 0">
                        <template #title>
                            {{ $t('application_information') }}
                        </template>
                        <template>
                            <ValidationObserver ref="formModalValidate">
                                <h6>{{ $t('personal_information') }}</h6>
                                <div class="border p-4 rounded-sm mb-3">
                                    <b-row>
                                        <b-col cols="12" md="6" lg="4">
                                            <b-form-group :label="$t('nationality')">
                                                <div class="label-as-input">{{ getObjectValue(formData, 'nationality') }}
                                                </div>
                                            </b-form-group>
                                        </b-col>
                                        <b-col cols="12" md="6" lg="4" v-if="formData.nationality_code">
                                            <b-form-group :label="$t('national_id')"
                                                v-if="formData.nationality_code == 'TR'">
                                                <div class="label-as-input">{{ getObjectValue(formData, 'national_id') }}
                                                </div>
                                            </b-form-group>
                                            <b-form-group :label="$t('passport_number')" v-else>
                                                <div class="label-as-input">{{ getObjectValue(formData, 'passport_number')
                                                }}</div>
                                            </b-form-group>
                                        </b-col>
                                    </b-row>
                                    <b-row>
                                        <b-col cols="12" md="6" lg="4">
                                            <b-form-group :label="$t('name')">
                                                <div class="label-as-input">{{ getObjectValue(formData, 'name') }}</div>
                                            </b-form-group>
                                        </b-col>
                                        <b-col cols="12" md="6" lg="4">
                                            <b-form-group :label="$t('surname')">
                                                <div class="label-as-input">{{ getObjectValue(formData, 'surname') }}</div>
                                            </b-form-group>
                                        </b-col>
                                        <b-col cols="12" md="6" lg="4">
                                            <b-form-group :label="$t('father_name')">
                                                <div class="label-as-input">{{ getObjectValue(formData, 'father_name') }}
                                                </div>
                                            </b-form-group>
                                        </b-col>
                                        <b-col cols="12" md="6" lg="4">
                                            <b-form-group :label="$t('birthdate')">
                                                <div class="label-as-input">{{ toLocaleDate(formData.birthdate) }}</div>
                                            </b-form-group>
                                        </b-col>
                                        <b-col cols="12" md="6" lg="4">
                                            <b-form-group :label="$t('birthplace')">
                                                <div v-if="applicationStatus == 'waiting_program'">
                                                    <ValidationProvider name="birthplace" rules="required"
                                                        v-slot="{ errors }">
                                                        <b-input-group class="mb-1">
                                                            <b-form-input v-model="formData.birthplace"
                                                                :disabled="application.type === 'in'"
                                                                :state="errors[0] ? false : null" />
                                                        </b-input-group>
                                                        <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"
                                                            class="mb-2" />
                                                    </ValidationProvider>
                                                </div>
                                                <div class="label-as-input" v-else>
                                                    {{ getObjectValue(formData, 'birthplace') }}
                                                </div>
                                            </b-form-group>
                                        </b-col>
                                        <b-col cols="12" md="6" lg="4">
                                            <b-form-group :label="$t('gender')">
                                                <div v-if="applicationStatus == 'waiting_program'">
                                                    <ValidationProvider name="gender" rules="required" v-slot="{ errors }">
                                                        <gender-selectbox v-model="formData.gender"
                                                            :disabled="application.type === 'in'"
                                                            :validateError="errors[0]" />
                                                    </ValidationProvider>
                                                </div>
                                                <div class="label-as-input" v-else>
                                                    {{ getObjectValue(formData, 'gender') }}
                                                </div>
                                            </b-form-group>
                                        </b-col>
                                    </b-row>
                                </div>

                                <h6>{{ $t('contact_information') }}</h6>
                                <div class="border p-4 rounded-sm mb-3">
                                    <b-row>
                                        <b-col cols="12" md="6" lg="4">
                                            <b-form-group :label="$t('address')">
                                                <div
                                                    v-if="applicationStatus == 'waiting_program' && applicationType != 'in'">
                                                    <ValidationProvider name="address" rules="required" v-slot="{ errors }">
                                                        <b-form-textarea v-model="formData.address" rows="4" maxlength="100"
                                                            :state="errors[0] ? false : null" />
                                                        <div class="invalid-feedback d-block" v-if="errors[0]"
                                                            v-html="errors[0]" />
                                                    </ValidationProvider>
                                                </div>
                                                <div class="label-as-input height-100" v-else>
                                                    {{ getObjectValue(formData, 'address') }}
                                                </div>
                                            </b-form-group>
                                        </b-col>
                                        <b-col cols="12" md="6" lg="4">
                                            <b-form-group :label="$t('email')">
                                                <div
                                                    v-if="applicationStatus == 'waiting_program' && applicationType != 'abroad'">
                                                    <ValidationProvider name="email" rules="email" v-slot="{ errors }">
                                                        <b-form-input v-model="formData.email" type="email"
                                                            :state="errors[0] ? false : null" />
                                                        <div class="invalid-feedback d-block" v-if="errors[0]"
                                                            v-html="errors[0]"></div>
                                                    </ValidationProvider>
                                                </div>
                                                <div class="label-as-input" v-else>{{ getObjectValue(formData, 'email') }}
                                                </div>
                                            </b-form-group>
                                        </b-col>
                                        <b-col cols="12" md="6" lg="4">
                                            <b-form-group :label="$t('telephone')">
                                                <div
                                                    v-if="applicationStatus == 'waiting_program' && applicationType == 'abroad'">
                                                    <ValidationProvider name="phone" rules="required" v-slot="{ errors }">
                                                        <b-form-input v-model="formData.phone"
                                                            :state="errors[0] ? false : null" />
                                                        <div class="invalid-feedback d-block" v-if="errors[0]"
                                                            v-html="errors[0]"></div>
                                                    </ValidationProvider>
                                                </div>
                                                <div class="label-as-input" v-else>{{ getObjectValue(formData, 'phone') }}
                                                </div>
                                            </b-form-group>
                                        </b-col>
                                    </b-row>
                                </div>

                                <h6>{{ $t('education_info') }}</h6>
                                <div class="border p-4 rounded-sm">
                                    <div v-if="applicationStatus == 'waiting_program'">
                                        <div v-if="applicationType == 'domestic'">
                                            <ValidationProvider name="unit_id" rules="required" v-slot="{ errors }">
                                                <b-form-group :label="$t('university')">
                                                    <div>
                                                        <b-form-select v-model="formData.unit_id"
                                                            :state="errors[0] ? false : null">
                                                            <b-form-select-option :value="null"></b-form-select-option>
                                                            <b-form-select-option v-for="option in yoksisPrograms"
                                                                :key="option.unit_id" :value="option.unit_id">
                                                                {{ option.university_name }} - {{ option.unit_name }}
                                                            </b-form-select-option>
                                                        </b-form-select>
                                                    </div>
                                                    <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"
                                                        class="mb-2" />
                                                </b-form-group>
                                            </ValidationProvider>
                                            <b-row>
                                                <b-col cols="12" md="6">
                                                    <b-form-group :label="$t('proficiency_status')">
                                                        <ValidationProvider name="proficiency_status" rules="required"
                                                            v-slot="{ errors }">
                                                            <b-form-select v-model="formData.proficiency_status"
                                                                :state="errors[0] ? false : null" :options="[
                                                                    { value: null, text: '' },
                                                                    { value: 'E', text: $t('studied') },
                                                                    { value: 'H', text: $t('not_studied') }
                                                                ]" />
                                                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"
                                                                class="mb-2" />
                                                        </ValidationProvider>
                                                    </b-form-group>
                                                </b-col>
                                                <b-col cols="12" md="6">
                                                    <b-form-group :label="$t('is_before_undergraduate_transfer')">
                                                        <ValidationProvider name="is_before_undergraduate_transfer"
                                                            rules="required" v-slot="{ errors }">
                                                            <true-false-selectbox
                                                                v-model="formData.before_undergraduate_transfer"
                                                                :validateError="errors[0]" />
                                                        </ValidationProvider>
                                                    </b-form-group>
                                                    <b-form-group :label="$t('is_before_undergraduate_transfer_type')"
                                                        v-if="formData.before_undergraduate_transfer">
                                                        <ValidationProvider name="before_undergraduate_transfer_type"
                                                            rules="required" v-slot="{ errors }">
                                                            <parameter-selectbox code="undergraduate_transfer_types"
                                                                v-model="formData.before_undergraduate_transfer_type"
                                                                :public="true" :validateError="errors[0]" />
                                                        </ValidationProvider>
                                                    </b-form-group>
                                                </b-col>
                                            </b-row>
                                        </div>
                                        <div v-else>
                                            <b-row>
                                                <b-col cols="12" md="6">
                                                    <b-form-group :label="$t('country')">
                                                        <div
                                                            v-if="applicationStatus == 'waiting_program' && applicationType == 'abroad'">
                                                            <country-selectbox v-model="formData.country_id"
                                                                :public="true" />
                                                        </div>
                                                        <div class="label-as-input" v-else>
                                                            {{ $t('turkey') }}
                                                        </div>
                                                    </b-form-group>
                                                    <b-form-group :label="$t('university')">
                                                        <div
                                                            v-if="applicationStatus == 'waiting_program' && applicationType == 'abroad'">
                                                            <university-selectbox v-model="formData.university_id"
                                                                transfer="abroad" :country-id="formData.country_id"
                                                                :is-country-required="true" :is-other="true"
                                                                :public="true" />
                                                        </div>
                                                        <div class="label-as-input" v-else>{{ getLocaleText(formData,
                                                            'university_name') }}</div>
                                                        <!-- <div v-if="applicationStatus=='waiting_program' && applicationType=='abroad' && formData.university_id=='other'">
                                                            <b-form-input v-model="formData.other_university"
                                                                          :placeholder="$t('other_university')"
                                                            />
                                                        </div> -->
                                                    </b-form-group>
                                                    <!-- <b-form-group :label="$t('university_not_list')">
                                                        <div class="d-flex flex-direction-row aling-items-center justify-content-center">
                                                            <b-form-checkbox
                                                            :value="false"
                                                            :unchecked-value="true"
                                                            v-model="formData.universityNot"
                                                        >
                                                        </b-form-checkbox>
                                                        <b-form-input :disabled="formData.universityNot" v-model="formData.other_university_name"
                                                        :placeholder="$t('other_university')"
                                                            />
                                                        </div>
                                                       
                                                    </b-form-group> -->
                                                    <b-form-group :label="$t('faculty')">
                                                        <div
                                                            v-if="applicationStatus == 'waiting_program' && applicationType == 'abroad'">
                                                            <ValidationProvider name="faculty" rules="required"
                                                                v-slot="{ errors }">
                                                                <b-form-input v-model="formData.faculty"
                                                                    :state="errors[0] ? false : null" />
                                                            </ValidationProvider>
                                                        </div>
                                                        <div class="label-as-input" v-else>{{ getObjectValue(formData,
                                                            'faculty') }}</div>
                                                    </b-form-group>
                                                    <b-form-group :label="$t('program')">
                                                        <div
                                                            v-if="applicationStatus == 'waiting_program' && applicationType == 'abroad'">
                                                            <ValidationProvider name="program" rules="required"
                                                                v-slot="{ errors }">
                                                                <b-form-input v-model="formData.program"
                                                                    :state="errors[0] ? false : null" />
                                                            </ValidationProvider>
                                                        </div>
                                                        <div class="label-as-input" v-else>{{ getObjectValue(formData,
                                                            'program') }}</div>
                                                    </b-form-group>
                                                    <b-form-group :label="$t('level')">
                                                        <div
                                                            v-if="applicationStatus == 'waiting_program' && applicationType == 'abroad'">
                                                            <ValidationProvider name="level" rules="required"
                                                                v-slot="{ errors }">
                                                                <parameter-selectbox v-model="formData.level"
                                                                    code="program_levels" :validateError="errors[0]"
                                                                    :public="true" />
                                                            </ValidationProvider>
                                                        </div>
                                                        <div class="label-as-input" v-else>{{ getLocaleText(formData,
                                                            'level_name') }}</div>
                                                    </b-form-group>
                                                </b-col>
                                                <b-col cols="12" md="6">
                                                    <b-form-group :label="$t('gno')">
                                                        <div
                                                            v-if="applicationStatus == 'waiting_program' && applicationType == 'abroad'">
                                                            <ValidationProvider name="gno" rules="required"
                                                                v-slot="{ errors }">
                                                                <b-form-input v-model="formData.gno" type="number"
                                                                    :state="errors[0] ? false : null" />
                                                                <div class="invalid-feedback d-block" v-if="errors[0]"
                                                                    v-html="errors[0]"></div>
                                                            </ValidationProvider>
                                                        </div>
                                                        <div class="label-as-input" v-else>{{ getObjectValue(formData,
                                                            'gno') }}</div>
                                                    </b-form-group>
                                                    <b-form-group :label="$t('number_of_semesters')">
                                                        <div
                                                            v-if="applicationStatus == 'waiting_program' && applicationType == 'abroad'">
                                                            <ValidationProvider name="semester_count" rules="required"
                                                                v-slot="{ errors }">
                                                                <semester-numbered-selectbox
                                                                    v-model="formData.semester_count"
                                                                    :validateError="errors[0]" />
                                                            </ValidationProvider>
                                                        </div>
                                                        <div class="label-as-input" v-else>
                                                            {{ formData.semester_count ? $t('semester_x', {
                                                                semester:
                                                                    formData.semester_count
                                                            }) : '-' }}
                                                        </div>
                                                    </b-form-group>
                                                    <b-form-group :label="$t('registration_date')">
                                                        <div
                                                            v-if="applicationStatus == 'waiting_program' && applicationType == 'abroad'">
                                                            <ValidationProvider name="registration_date" rules="required"
                                                                v-slot="{ errors }">
                                                                <select-date v-model="formData.registration_date"
                                                                    :validationError="errors[0]" />
                                                            </ValidationProvider>
                                                        </div>
                                                        <div class="label-as-input" v-else>
                                                            {{ toLocaleDate(getObjectValue(formData, 'registration_date'))
                                                            }}
                                                        </div>
                                                    </b-form-group>
                                                    <b-form-group :label="$t('proficiency_status')">
                                                        <div
                                                            v-if="applicationStatus == 'waiting_program' && applicationType == 'abroad'">
                                                            <ValidationProvider name="proficiency_status" rules="required"
                                                                v-slot="{ errors }">
                                                                <true-false-selectbox v-model="formData.proficiency_status"
                                                                    :options="proficiencyStatusOptions"
                                                                    :validateError="errors[0]" />
                                                            </ValidationProvider>
                                                        </div>
                                                        <div class="label-as-input" v-else>
                                                            <div v-if="isNotNull(formData.proficiency_status)">
                                                                {{ formData.proficiency_status ? $t('studied') :
                                                                    $t('not_studied') }}
                                                            </div>
                                                            <div v-else>-</div>
                                                        </div>
                                                    </b-form-group>
                                                    <b-form-group :label="$t('is_before_undergraduate_transfer')">
                                                        <div v-if="applicationStatus == 'waiting_program'">
                                                            <ValidationProvider name="before_undergraduate_transfer"
                                                                rules="required" v-slot="{ errors }">
                                                                <true-false-selectbox
                                                                    v-model="formData.before_undergraduate_transfer"
                                                                    :validateError="errors[0]" />
                                                            </ValidationProvider>
                                                        </div>
                                                        <div class="label-as-input" v-else>
                                                            {{ formData.before_undergraduate_transfer ? $t('yes') : $t('no')
                                                            }}
                                                        </div>
                                                    </b-form-group>
                                                    <b-form-group :label="$t('is_before_undergraduate_transfer_type')"
                                                        v-if="formData.before_undergraduate_transfer">
                                                        <div
                                                            v-if="applicationStatus == 'waiting_program' && formData.before_undergraduate_transfer">
                                                            <ValidationProvider name="before_undergraduate_transfer_type"
                                                                rules="required" v-slot="{ errors }">
                                                                <parameter-selectbox code="undergraduate_transfer_types"
                                                                    v-model="formData.before_undergraduate_transfer_type"
                                                                    :public="true" :validateError="errors[0]" />
                                                            </ValidationProvider>
                                                        </div>
                                                        <div class="label-as-input" v-else>
                                                            {{ formData.is_before_undergraduate_transfer_type }}
                                                        </div>
                                                    </b-form-group>
                                                </b-col>
                                            </b-row>
                                        </div>
                                    </div>
                                    <div v-else>
                                        <b-row>
                                            <b-col cols="12" md="6">
                                                <b-form-group :label="$t('country')">
                                                    <div class="label-as-input">
                                                        {{ $t('turkey') }}
                                                    </div>
                                                </b-form-group>
                                                <b-form-group :label="$t('university')">
                                                    <div class="label-as-input">
                                                        {{ getLocaleText(formData, 'university_name') }}
                                                        {{ getLocaleText(formData, 'other_university') }}
                                                    </div>
                                                </b-form-group>
                                                <b-form-group :label="$t('faculty')">
                                                    <div class="label-as-input">
                                                        {{ getObjectValue(formData, 'faculty') }}
                                                    </div>
                                                </b-form-group>
                                                <b-form-group :label="$t('program')">
                                                    <div class="label-as-input">
                                                        {{ getObjectValue(formData, 'program') }}
                                                    </div>
                                                </b-form-group>
                                                <b-form-group :label="$t('level')">
                                                    <div class="label-as-input">
                                                        {{ getLocaleText(formData, 'level_name') }}
                                                    </div>
                                                </b-form-group>
                                            </b-col>
                                            <b-col cols="12" md="6">
                                                <b-form-group :label="$t('gno')">
                                                    <div class="label-as-input">
                                                        {{ getObjectValue(formData, 'gno') }}
                                                    </div>
                                                </b-form-group>
                                                <b-form-group :label="$t('finished_class')">
                                                    <div class="label-as-input">
                                                        {{ getObjectValue(formData, 'finished_class', '') ? $t('class_x',
                                                            { 'class': getObjectValue(formData, 'finished_class', '') }) : '-'
                                                        }}
                                                    </div>
                                                </b-form-group>
                                                <b-form-group :label="$t('number_of_semesters')">
                                                    <div class="label-as-input">
                                                        {{ formData.semester_count ? $t('semester_x', {
                                                            semester:
                                                                formData.semester_count
                                                        }) : '-' }}
                                                    </div>
                                                </b-form-group>
                                                <b-form-group :label="$t('registration_date')">
                                                    <div class="label-as-input">
                                                        {{ toLocaleDate(getObjectValue(formData, 'registration_date')) }}
                                                    </div>
                                                </b-form-group>
                                                <b-form-group :label="$t('proficiency_status')">
                                                    <div class="label-as-input">
                                                        <div v-if="isNotNull(formData.proficiency_status)">
                                                            {{ formData.proficiency_status ? $t('studied') :
                                                                $t('not_studied') }}
                                                        </div>
                                                        <div v-else>-</div>
                                                    </div>
                                                </b-form-group>
                                                <b-form-group :label="$t('is_before_undergraduate_transfer')">
                                                    <div class="label-as-input">
                                                        {{ formData.before_undergraduate_transfer ? $t('yes') : $t('no') }}
                                                    </div>
                                                </b-form-group>
                                                <b-form-group :label="$t('is_before_undergraduate_transfer_type')"
                                                    v-if="formData.before_undergraduate_transfer">
                                                    <div class="label-as-input">
                                                        {{ this.getLocaleText(formData,
                                                            'before_undergraduate_transfer_text')
                                                        }}
                                                    </div>
                                                </b-form-group>
                                            </b-col>
                                        </b-row>
                                    </div>
                                </div>
                                <div class="d-flex justify-content-center mt-3"
                                    v-if="applicationStatus == 'waiting_program'">
                                    <processing-button :processing="formLoading" variant="primary"
                                        :label="$t('save').toUpper()" @click="save" />
                                </div>
                            </ValidationObserver>
                        </template>
                    </b-tab>
                    <b-tab :active="tabIndex == 1" @click="tabIndex = 1">
                        <template #title>
                            {{ $t('preference_information') }}
                        </template>
                        <template>
                            <div v-if="applicationStatus == 'waiting_program'">
                                <div class="pt-5 mb-2 text-center">
                                    {{ applicationStatusMessage }}
                                </div>
                                <div class="d-flex justify-content-center">
                                    <b-button variant="primary" @click="tabIndex = 0">
                                        {{ $t('back').toUpper() }}
                                    </b-button>
                                </div>
                            </div>
                            <div v-else>
                                <b-button @click="preferenceFormShow()" variant="primary" class="mb-2"
                                    v-if="(preferences && preferences.length < 2) && applicationStatus == 'waiting_preference'">
                                    {{ $t('add') }}
                                </b-button>
                                <b-table :empty-filtered-text="$t('no_result')" :empty-text="$t('no_result')" bordered
                                    responsive :items="preferences" :fields="preferencesFields" show-empty
                                    class="mb-4 table-dropdown no-scrollbar border rounded">
                                    <template #cell(rank)="row">
                                        <div class="d-flex justify-content-center align-items-center">
                                            <i v-if="preferences.length > 1" @click="preferencesRankChange"
                                                :class="`${row.item.rank === 1 ? 'ri-arrow-down-line' : 'ri-arrow-up-line'}`"
                                                class="mr-3" style="font-size: 20px;"></i>
                                            <span style="font-size: 16px;"> {{ row.item.rank }} </span>
                                        </div>
                                    </template>
                                    <template #cell(faculty_program)="row">
                                        {{ row.item.faculty ? getLocaleText(row.item.faculty, 'name') : ' - ' }} <br />
                                        {{ row.item.program ? getLocaleText(row.item.program, 'name') : ' - ' }}
                                    </template>
                                    <template #cell(status)="row">
                                        {{ preferenceStatusText(row.item) }}
                                    </template>
                                    <template #cell(class)="row">
                                        <template v-if="row.item.result">
                                            {{ getLocaleText(getClassNameWithCode(row.item.class), 'name') || "-" }}
                                        </template>

                                    </template>
                                    <template #cell(process)="row">
                                        <b-button @click="deletePreference(row.item.id)" size="sm" type="button"
                                            variant="danger">
                                            {{ $t('delete').toUpper() }}
                                        </b-button>
                                    </template>
                                    <template #cell(result)="row">
                                        <template v-if="!row.item.result">-</template>
                                        <template v-else>
                                            <span class="badge" :class="['placed', 'other_preference_placed'].includes(row.item.result) ? 'badge-success'
                                                : row.item.result == 'not_placed' ? 'badge-danger' : 'badge-warning'">
                                                {{ $t(`application_result.${row.item.result}`) }}
                                            </span>
                                        </template>
                                    </template>
                                </b-table>
                                <div class="d-flex justify-content-center">
                                    <b-button @click="sendPreferences()" variant="primary" class="mb-2"
                                        v-if="applicationStatus == 'waiting_preference' && preferences.length <= 2">
                                        {{ $t('send') }}
                                    </b-button>
                                </div>
                            </div>
                        </template>
                        <CommonModal ref="preferenceFormModal" size="xl" :onHideOnlyX="true"
                            @bHideModalHeaderClose="formClear">
                            <template v-slot:CommonModalTitle>
                                {{ $t('your_preferences').toUpper() }}
                            </template>
                            <template v-slot:CommonModalContent>
                                <preference-form :pin="pin" :undergraduate_transfer_id="application.id"
                                    :preferences="preferences" :applicationType="applicationType"
                                    @savePreferences="preferenceFormClose"
                                    v-if="modalProcess == 'preferences' && tabIndex == 1" />
                            </template>
                        </CommonModal>
                    </b-tab>
                    <b-tab :active="tabIndex == 2" @click="tabIndex = 2">
                        <template #title>
                            {{ $t('documents') }}
                        </template>
                        <template>
                            <div v-if="applicationStatus == 'waiting_program'">
                                <div class="pt-5 mb-2 text-center">
                                    {{ applicationStatusMessage }}
                                </div>
                                <div class="d-flex justify-content-center">
                                    <b-button variant="primary" @click="tabIndex = 0">
                                        {{ $t('back').toUpper() }}
                                    </b-button>
                                </div>
                            </div>
                            <div v-else-if="applicationStatus == 'waiting_preference'">
                                <div class="pt-5 mb-2 text-center">
                                    {{ applicationStatusMessage }}
                                </div>
                                <div class="d-flex justify-content-center">
                                    <b-button variant="primary" @click="tabIndex = 1">
                                        {{ $t('back').toUpper() }}
                                    </b-button>
                                </div>
                            </div>
                            <div v-else>
                                <b-table :empty-filtered-text="$t('no_result')" :empty-text="$t('no_result')" bordered
                                    responsive :items="requiredDocuments" :fields="requiredDocumentsFields" show-empty
                                    class="mb-4 table-dropdown table-sm no-scrollbar border rounded">
                                    <template #cell(status)="row">
                                        <span class="badge" :class="getButtonColors(row.value)">
                                            {{ $t('status_' + row.value) }}
                                            <span v-if="row.value == 'uploaded'"><br>{{ $t('status_approval_awaited') }}
                                            </span>
                                        </span>
                                    </template>
                                    <template #cell(process)="row">
                                        <div v-if="row.item.status == 'uploaded'" class="d-flex justify-content-center">
                                            <b-button @click="uploadFormDownloadFile(row.item.key, row.item.filename)"
                                                size="sm" type="button" variant="light"
                                                v-b-popover.hover.bottom="$t('download')" class="mr-2"
                                                style="background: #e9e9e9">
                                                <i class="ri-download-2-line"></i>
                                            </b-button>
                                            <b-button @click="uploadFormDeleteFile(row.item.key)" size="sm" type="button"
                                                variant="danger" v-b-popover.hover.bottom="$t('delete')">
                                                <i class="ri-close-line"></i>
                                            </b-button>
                                        </div>
                                        <div class="d-flex justify-content-center" v-else>
                                            <b-button @click="uploadFormShow(row.item.key)" size="sm" type="button"
                                                variant="primary" v-b-popover.hover.bottom="$t('upload')"
                                                :disabled="!['waiting', 'uploaded', 'declined'].includes(row.item.status)">
                                                <i class="ri-add-line"></i>
                                            </b-button>
                                        </div>
                                    </template>
                                </b-table>
                                <div class="d-flex justify-content-center">
                                    <span class="font-weight-bold">{{ $t('not_required_documents') }}</span>
                                </div>
                                <div class="d-flex justify-content-center mt-3">
                                    <b-button @click="uploadFormSendApprove()" variant="primary" class="mb-2"
                                        :disabled="!(allowRequiredDocumentsSendApprove && ['waiting_documents', 'missing_document'].includes(applicationStatus))">
                                        {{ $t('send_approve') }}
                                    </b-button>
                                </div>
                                <CommonModal ref="uploadFormModal" :onHideOnlyX="true"
                                    @bHideModalHeaderClose="uploadFormClose">
                                    <template v-slot:CommonModalTitle>
                                        {{ $t('upload').toUpper() }}
                                    </template>
                                    <template v-slot:CommonModalContent>
                                        <b-form-group :label="$t('file')" class="mb-2">
                                            <div class="d-flex custom-file-upload mb-2">
                                                <b-form-file v-model="uploadFormFile" :placeholder="$t('select_file')"
                                                    :drop-placeholder="$t('drop_file')" ref="fileInput"
                                                    accept=".jpg, .png, .gif, .pdf">
                                                </b-form-file>
                                                <b-button variant="outline-secondary"
                                                    @click="$refs.fileInput.$el.childNodes[0].click();">
                                                    <i class="ri-add-fill"></i>
                                                </b-button>
                                            </div>
                                        </b-form-group>
                                        <b-button variant="primary" block :disabled="!uploadFormFile" @click="uploadForm()">
                                            {{ $t('upload') }}
                                        </b-button>
                                    </template>
                                </CommonModal>
                            </div>
                        </template>
                    </b-tab>
                    <b-tab :active="tabIndex == 3" @click="tabIndex = 3">
                        <template #title>
                            {{ $t('result') }}
                        </template>
                        <div class="mt-5 mb-5 text-center">
                            <template v-if="application.result">
                                <div class="d-inline-flex flex-column">
                                    {{ $t('your_application_publish') }}
                                    <b-badge
                                        :variant="(application.result == 'placed' ? 'success' : 'danger') + ' text-uppercase'"
                                        class="mt-3">
                                        {{ $t(`application_result.${application.result}`) }}
                                    </b-badge>
                                </div>
                            </template>
                            <template v-else>
                                {{ applicationStatusMessage }}
                            </template>
                        </div>
                        <div class="d-flex justify-content-center">
                            <div v-if="applicationStatus == 'waiting_program'">
                                <b-button variant="primary" @click="tabIndex = 0">
                                    {{ $t('back').toUpper() }}
                                </b-button>
                            </div>
                            <div v-if="applicationStatus == 'waiting_preference'">
                                <b-button variant="primary" @click="tabIndex = 1">
                                    {{ $t('back').toUpper() }}
                                </b-button>
                            </div>
                            <div v-if="applicationStatus == 'waiting_documents' || applicationStatus == 'waiting_approve'">
                                <b-button variant="primary" @click="tabIndex = 2">
                                    {{ $t('back').toUpper() }}
                                </b-button>
                            </div>
                        </div>
                    </b-tab>
                </b-tabs>
            </div>
        </div>
    </layout>
</template>

<script>
// Layouts
import Layout from '../../layout/Layout'

// Component
import ClassesSelectbox from '@/components/interactive-fields/ClassesSelectbox'
import CountrySelectbox from '@/components/interactive-fields/CountrySelectbox'
import FacultySelectbox from '@/components/interactive-fields/FacultySelectbox'
import GenderSelectbox from '@/components/interactive-fields/GenderSelectbox'
import InfoCollapseArea from '@/components/elements/InfoCollapseArea'
import NationalitySelectbox from '@/components/interactive-fields/NationalitySelectbox'
import ParameterSelectbox from '@/components/interactive-fields/ParameterSelectbox'
import ProcessingButton from '@/components/elements/ProcessingButton'
import ProgramSelectbox from '@/components/interactive-fields/ProgramSelectbox'
import SelectDate from '@/components/interactive-fields/SelectDate'
import SemesterNumberedSelectbox from '@/components/interactive-fields/SemesterNumberedSelectbox'
import TrueFalseSelectbox from '@/components/interactive-fields/TrueFalseSelectbox'
import UniversitySelectbox from '@/components/interactive-fields/UniversitySelectbox'

// Services
import UndergraduateTransferService from '@/services/UndergraduateTransferService'
import UndergraduateTransferPreferenceService from '@/services/UndergraduateTransferPreferenceService'
import ConstantService from '@/services/ConstantService'
import ParameterService from '@/services/ParameterService'

// Pages
import PreferenceForm from './PreferenceForm'

// Other
import CommonModal from '@/components/elements/CommonModal';
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import isset from 'isset-php'

import qs from "qs"

export default {
    name: "TransferPage",
    components: {
        Layout,
        ClassesSelectbox,
        CountrySelectbox,
        FacultySelectbox,
        GenderSelectbox,
        InfoCollapseArea,
        NationalitySelectbox,
        ParameterSelectbox,
        ProcessingButton,
        ProgramSelectbox,
        SelectDate,
        SemesterNumberedSelectbox,
        TrueFalseSelectbox,
        UniversitySelectbox,
        PreferenceForm,
        CommonModal,
        ValidationObserver,
        ValidationProvider
    },
    computed: {
        applicationStatus() {
            let data = this.$store.getters['undergraduateTransfer/getData'];
            return isset(() => data.undergraduate_transfer.status) ? data.undergraduate_transfer.status : null
        },
        applicationStatusMessage() {
            if (this.applicationStatus == 'waiting_program') {
                return this.$t('application_information_is_awaited');
            }
            else if (this.applicationStatus == 'waiting_preference') {
                return this.$t('preference_information_is_awaited');
            }
            else if (this.applicationStatus == 'waiting_documents') {
                return this.$t('required_documents_is_awaited');
            }
            else if (this.applicationStatus == 'waiting_approve') {
                return this.$t('required_documents_approval_is_awaited');
            }
            else if (this.applicationStatus == 'approved') {
                return this.$t('your_application_has_been_accepted');
            }
            else if (this.applicationStatus == 'declined') {
                return this.$t('your_application_has_been_rejected');
            }
            else if (this.applicationStatus == 'publish') {
                return this.$t('your_application_publish');
            }
            else if (this.applicationStatus) {
                return this.$t('your_application_has_been_evaluated');
            }
            else {
                return '';
            }
        },
        educationInfoMdLgCol() {
            return this.applicationType == 'abroad' ? 6 : 4;
        },
        preferencesFields() {
            let fields = [
                {
                    key: 'rank',
                    label: this.$t('rank').toUpper(),
                    tdClass: 'text-center align-middle',
                    thClass: 'text-center',
                    sortable: false
                },
                {
                    key: 'faculty_program',
                    label: this.$t('faculty').toUpper() + ' / ' + this.$t('program').toUpper(),
                    tdClass: 'align-middle'
                },
                {
                    key: 'status',
                    label: this.$t('status').toUpper(),
                    tdClass: 'text-center align-middle',
                    thClass: 'text-center',
                    sortable: false,
                    formatFn: (value, row) => {
                        let variant = 'badge-light';
                        if (value.includes('waiting')) variant = 'badge-warning';
                        else if (value == 'declined') variant = 'badge-danger';
                        else if (value == 'approved') variant = 'badge-success';

                        return '<span class="badge ' + variant + '">' + row.status_text + '</span>';
                    }
                },
                {
                    key: 'class',
                    label: this.$t('class').toUpper(),
                    tdClass: 'align-middle'
                },
                {
                    key: 'result',
                    label: this.$t('result').toUpper(),
                    tdClass: 'align-middle'
                },
            ];
            if (this.applicationStatus == 'waiting_preference') {
                fields.push({
                    key: 'process',
                    label: '',
                    tdClass: 'd-flex justify-content-center align-middle',
                    thClass: 'text-center',
                    sortable: false
                });
            }

            return fields;
        },
        requiredDocumentsFields() {
            let fields = [];
            fields.push({
                key: 'process',
                label: '',
                tdClass: 'width-100 text-center',
                thClass: 'text-center',
                sortable: false
            });
            fields.push({
                key: 'name',
                label: this.$t('name').toUpper(),
                tdClass: 'text-center align-middle',
                thClass: 'text-center',
                sortable: false
            });

            fields.push({
                key: 'status',
                label: this.$t('status').toUpper(),
                tdClass: 'text-center align-middle',
                thClass: 'text-center',
                sortable: false
            });



            return fields;
        },
        allowRequiredDocumentsSendApprove() {
            let status = false;
            const requiredDocuments = this.requiredDocuments.filter(item => item.required == "1" || item.required === true || item.required === 1)
            if (requiredDocuments && requiredDocuments.length > 0) {
                let waitingRequiredDocuments = requiredDocuments.filter(item => item.status == 'waiting');
                if (waitingRequiredDocuments.length == 0) {
                    status = true;
                }
            }
            return status;
        }
    },
    watch: {
        tabIndex: function (newValue, oldValue) {
            if (newValue && newValue != oldValue) {
                if (newValue == 1 && this.applicationStatus && this.applicationStatus != 'waiting_program') {
                    this.getPreferences();
                }
                else if (newValue == 2 && this.applicationStatus && !['waiting_program', 'waiting_preference'].includes(this.applicationStatus)) {
                    this.getRequiredDocuments();
                }
            }
        }
    },
    data() {
        return {
            // Tab
            tabIndex: 0,

            // Pin
            pin: null,

            // Data
            applicationType: null,
            applicationTypes: ['in', 'domestic', 'abroad'],
            application: {},
            yoksisPrograms: [],
            statuses: {},
            classOptions: [],
            // Form
            formLoading: false,
            formData: {},

            // Preferences
            preferences: [],

            // Documents
            requiredDocuments: [],
            uploadFormType: null,
            uploadFormFile: null,

            // Modal
            modalProcess: null,
            modalData: null,

            // Other
            dateMask: {
                input: 'DD-MM-YYYY'
            },
            turkeyNationalityCode: 'TR',
            turkeyCountryId: null,

            proficiencyStatusOptions: [
                { value: null, text: '' },
                { value: 'E', text: this.$t('studied') },
                { value: 'H', text: this.$t('not_studied') }
            ]
        }
    },
    mounted() {
        this.$store.dispatch('undergraduateTransfer/isLogin');
        let data = this.$store.getters['undergraduateTransfer/getData'];
        if (data) {
            this.applicationType = data.type;
            this.application = data.undergraduate_transfer
            this.yoksisPrograms = data.yoksis_programs ? data.yoksis_programs : {}
        }

        // Pin
        this.pin = this.$store.getters['undergraduateTransfer/getPin'];

        this.formData = null
        this.setFormData();

        this.goToTab();
        this.setCountryId()
        this.getPublishStatus()
    },
    methods: {
        preferenceStatusText(item) {
            if (item.status === "approved") {
                return this.$t('your_application_has_been_evaluated')
            } else if (item.status === "declined") {
                return `${this.$t('accepttance_not_accepted')}: ${item.explanation}`
            } else if (item.status === "placed") {
                return this.$t('program_accepted')
            } else if (item.status === "not_placed") {
                return this.$t('program_spare')
            } else if (item.status === "not_placed_for_university_rule") {
                return `${this.$t('not_placed')}, ${this.$t('program_spare')}`
            } else if (item.status === "other_preference_placed") {
                return this.$t('program_other_placed')
            } else if (item.status === "not_completed") {
                return this.$t('not_completed_undergraduate')
            } else {
                return this.getLocaleText(item, 'status_text')
            }
        },
        async preferencesRankChange() {
            await UndergraduateTransferPreferenceService.changeRanks({
                undergraduate_transfer_id: this.application.id,
                pin: this.pin
            })
                .catch((e) => {
                    this.showErrors(e);
                })
            this.getPreferences()
        },
        async setCountryId() {
            const config = {
                params: {
                    key: "turkey_country_id",
                },
                paramsSerializer: (params) => qs.stringify(params, { encode: false })
            }
            const response = await ConstantService.getValue(config)
                .catch((e) => {
                    this.showErrors(e);
                })

            if (response.data.success) {
                this.formData.country_id = response.data.data
            }
        },
        getClassNameWithCode(code) {
            const placedClass = this.classOptions.find(item => item.code == code)
            if (placedClass) {
                return placedClass
            }
            return ""
        },
        async getPublishStatus() {
            this.classOptions = [];

            const config = {
                params: {
                    parameter_code: "classes",
                    limit: -1,
                },
                paramsSerializer: (params) =>
                    qs.stringify(params, { encode: false }),
            };

            ParameterService.getItemsPublic(config)
                .then((response) => {
                    this.classOptions = response.data.data;
                })
        },
        goToTab() {
            if (this.applicationStatus) {
                if (this.applicationStatus == 'waiting_program') {
                    this.tabIndex = 0;
                }
                else if (this.applicationStatus == 'waiting_preference') {
                    this.tabIndex = 1;
                }
                else if (this.applicationStatus == 'waiting_documents') {
                    this.tabIndex = 2;
                }
                else if (this.applicationStatus == 'waiting_approve') {
                    this.tabIndex = 3;
                }
            }
        },

        // Set Form Data
        setFormData() {
            this.formData = {
                pin: this.pin,
                undergraduate_transfer_id: this.application.id,

                // Personal Information
                nationality: this.application.nationality,
                nationality_code: this.application.nationality_code ? this.application.nationality_code : this.turkeyNationalityCode,
                national_id: this.application.national_id,
                passport_number: this.application.passport_number,

                name: this.application.name,
                surname: this.application.surname,
                gender: this.application.gender,

                birthdate: this.application.birthdate,
                birthplace: this.application.birthplace,
                father_name: this.application.father_name,
                address: this.application.address,
                email: this.application.email,
                phone: this.application.phone,

                // Education
                country_id: this.application.country_id ? this.application.country_id : this.turkeyCountryId,
                university_id: this.application.university_id,
                university_name: this.application.university_name,
                university_name_en: this.application.university_name_en,
                other_university: this.application.other_university ? this.application.other_university : null,
                faculty: this.application.faculty,
                program: this.application.program,

                level: this.application.level,
                level_name: this.application.level_name,
                level_name_en: this.application.level_name_en,
                unit_id: this.application.unit_id,
                exam_type: this.application.exam_type,
                point_type: this.application.point_type,
                entry_type: this.application.entry_type,

                gno: this.application.gno,
                finished_class: this.application.finished_class,
                semester_count: this.application.semester_count,
                registration_date: this.application.registration_date,
                proficiency_status: this.application.proficiency_status,
                before_undergraduate_transfer: this.application.before_undergraduate_transfer,
                before_undergraduate_transfer_type: this.application.before_undergraduate_transfer_type,
                before_undergraduate_transfer_type_text: this.application.before_undergraduate_transfer_type_text,
                before_undergraduate_transfer_type_text_en: this.application.before_undergraduate_transfer_type_text_en
            }
        },

        // Save
        async save() {
            const isValid = await this.$refs.formModalValidate.validate();
            if (isValid) {
                let formData = {
                    pin: this.pin,
                    ...this.formData,
                    before_undergraduate_transfer: this.formData.before_undergraduate_transfer ? 1 : 0
                }
                this.$store.commit('undergraduateTransfer/setStatus', 'waiting_preference');

                if (formData.university_id == "other") {
                    formData.university_id = null
                }

                this.formLoading = true
                UndergraduateTransferService.save(formData)
                    .then((response) => {
                        let data = response.data.data;

                        this.application = data.undergraduate_transfer
                        this.setFormData();
                        this.$store.commit('undergraduateTransfer/setData', data)

                        this.$toast.success(this.$t('successfully_saved'));
                        this.tabIndex = 1;
                    })
                    .catch((e) => {
                        this.showErrors(e);
                    })
                    .finally(() => {
                        this.formLoading = false
                    })

            }
        },

        // Preferences
        getPreferences() {
            let formData = {
                undergraduate_transfer_id: this.application.id,
                pin: this.pin
            }
            UndergraduateTransferPreferenceService.index(formData)
                .then((response) => {
                    this.preferences = response.data.data.sort((a, b) => a.rank - b.rank);
                })
                .catch((e) => {
                    this.showErrors(e);
                })
        },
        deletePreference(id) {
            this.$swal
                .fire({
                    text: this.$t('are_you_sure_to_delete'),
                    showCancelButton: true,
                    confirmButtonText: this.$t('yes'),
                    cancelButtonText: this.$t('no'),
                    icon: 'question'
                })
                .then((result) => {
                    if (result.isConfirmed) {
                        let formData = {
                            undergraduate_transfer_id: this.application.id,
                            pin: this.pin,
                            undergraduate_transfer_preference_id: id
                        }
                        UndergraduateTransferPreferenceService.del(formData)
                            .then((response) => {
                                this.getPreferences();
                            })
                            .catch((e) => {
                                this.showErrors(e);
                            })
                    }
                });
        },
        sendPreferences() {
            this.$swal
                .fire({
                    text: this.$t('are_you_sure_to_send'),
                    showCancelButton: true,
                    confirmButtonText: this.$t('yes'),
                    cancelButtonText: this.$t('no'),
                    icon: 'question'
                })
                .then((result) => {
                    if (result.isConfirmed) {
                        let formData = {
                            undergraduate_transfer_id: this.application.id,
                            pin: this.pin
                        }
                        UndergraduateTransferPreferenceService.save(formData)
                            .then(() => {
                                this.application.status = 'waiting_documents'
                                this.$store.commit('undergraduateTransfer/setStatus', 'waiting_documents')
                                this.tabIndex = 2;
                            })
                            .catch((e) => {
                                this.showErrors(e);
                            })
                    }
                });
        },

        // Documents
        getRequiredDocuments() {
            let formData = {
                undergraduate_transfer_id: this.application.id,
                pin: this.pin
            }
            this.requiredDocuments = [];
            UndergraduateTransferService.requiredDocuments(formData)
                .then((response) => {
                    let data = response.data.data;
                    if (Object.keys(data)) {
                        Object.keys(data).forEach(key => {
                            this.requiredDocuments.push({
                                key: key,
                                ...data[key],
                            })
                        })
                    }
                })
                .catch((e) => {
                    this.showErrors(e);
                })
        },

        // Form Clear
        formClear() {
            this.modalData = null
            this.modalProcess = null

            this.uploadFormType = null
            this.uploadFormFile = null
        },

        // Update Form
        preferenceFormShow() {
            this.modalProcess = 'preferences'
            this.$refs.preferenceFormModal.$refs.commonModal.show()
        },
        preferenceFormClose() {
            this.$refs.preferenceFormModal.$refs.commonModal.hide()
            this.getPreferences()
            this.formClear();
        },

        // UploadForm
        uploadFormShow(type) {
            this.uploadFormType = type
            this.modalProcess = 'upload'
            this.$refs.uploadFormModal.$refs.commonModal.show()
        },
        uploadFormClose() {
            this.$refs.uploadFormModal.$refs.commonModal.hide()
            this.getRequiredDocuments()
            this.formClear()
        },
        uploadForm() {
            if (!this.uploadFormFile) {
                // this.$toast.error(this.$t('api.' + response.data.message));
            }
            else {
                let formData = new FormData();
                formData.append('undergraduate_transfer_id', this.application.id);
                formData.append('pin', this.pin);
                formData.append('type', this.uploadFormType);
                formData.append('file', this.uploadFormFile)

                UndergraduateTransferService.uploadDocument(formData)
                    .then(response => {
                        this.$toast.success(this.$t('api.' + response.data.message));
                        this.uploadFormClose();
                    })
                    .catch(e => {
                        this.showErrors(e);
                    })

            }
        },
        uploadFormDownloadFile(type, filename) {
            let formData = {
                'undergraduate_transfer_id': this.application.id,
                'pin': this.pin,
                'type': type
            }
            UndergraduateTransferService.downloadDocument(formData)
                .then(response => {
                    this._downloadFile(response, filename)
                })
                .catch(e => {
                    this.showErrors(e);
                })
        },
        uploadFormDeleteFile(type) {
            this.$swal
                .fire({
                    text: this.$t('are_you_sure_to_delete'),
                    showCancelButton: true,
                    confirmButtonText: this.$t('yes'),
                    cancelButtonText: this.$t('no'),
                    icon: 'question'
                })
                .then((result) => {
                    if (result.isConfirmed) {
                        let formData = {
                            'undergraduate_transfer_id': this.application.id,
                            'pin': this.pin,
                            'type': type,
                        }
                        UndergraduateTransferService.deleteDocument(formData)
                            .then(response => {
                                this.$toast.success(this.$t('api.' + response.data.message));
                                this.getRequiredDocuments()
                            })
                            .catch(e => {
                                this.showErrors(e);
                            })
                    }
                });

        },
        uploadFormSendApprove() {
            this.$swal
                .fire({
                    text: this.$t('are_you_sure_to_send'),
                    showCancelButton: true,
                    confirmButtonText: this.$t('yes'),
                    cancelButtonText: this.$t('no'),
                    icon: 'question'
                })
                .then((result) => {
                    if (result.isConfirmed) {
                        let formData = {
                            'undergraduate_transfer_id': this.application.id,
                            'pin': this.pin
                        }
                        UndergraduateTransferService.sendApprove(formData)
                            .then(response => {
                                this.application.status = 'waiting_approve'
                                this.$store.commit('undergraduateTransfer/setStatus', 'waiting_approve');
                                this.$toast.success(this.$t('api.' + response.data.message));
                                this.tabIndex = 3;
                            })
                            .catch(e => {
                                this.showErrors(e);
                            })
                    }
                })
        }
    }
}
</script>
